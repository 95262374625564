import { $ } from "~/zeus";
import { LocaleInputType } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const loginUserMutation = typedGql("mutation")({
  LoginUser: [
    {
      email: $("email", "String"),
      password: $("password", "String"),
      sha1Password: $("sha1Password", "String"),
    },
    {
      token: true,
      exp: true,
      user: {
        id: true,
        email: true,
        roles: true,
        name: true,
        organiser: {
          logo: [
            {},
            {
              url: true,
            },
          ],
          banner: [
            {},
            {
              url: true,
            },
          ],
          description: true,
          foundedIn: true,
          address: true,
          website: true,
          facebook: true,
          instagram: true,
          phone: true,
        },
        member: {
          avatar: [
            {},
            {
              url: true,
            },
          ],
          birthday: true,
          gender: true,
          phone: true,
          categories: [
            {
              locale: LocaleInputType.zh,
            },
            {
              id: true,
              name: true,
              icon: [
                {},
                {
                  url: true,
                },
              ],
            },
          ],
          region: true,
          language: true,
        },
      },
    },
  ],
});

export default loginUserMutation;
