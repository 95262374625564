import { typedGql } from "~/zeus/typedDocumentNode";
import { LocaleInputType } from "~/zeus";

const meUserQuery = typedGql("query")({
  meUser: {
    token: true,
    exp: true,
    user: {
      id: true,
      email: true,
      name: true,
      roles: true,
      organiser: {
        logo: [
          {},
          {
            url: true,
          },
        ],
        banner: [
          {},
          {
            url: true,
          },
        ],
        description: true,
        foundedIn: true,
        address: true,
        website: true,
        facebook: true,
        instagram: true,
        phone: true,
      },
      member: {
        avatar: [
          {
            locale: LocaleInputType.zh,
          },
          {
            id: true,
            url: true,
          },
        ],
        birthday: true,
        gender: true,
        categories: [
          {
            locale: LocaleInputType.zh,
          },
          {
            id: true,
            name: true,
            icon: [
              {},
              {
                url: true,
              },
            ],
          },
        ],
        phone: true,
        language: true,
        region: true,
      },
      sso: {
        googleId: true,
        facebookId: true,
        appleId: true,
      },
    },
  },
});

export default meUserQuery;
