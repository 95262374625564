import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";
import { LocaleInputType } from "~/zeus";

const updateUserMutation = typedGql("mutation")({
    updateUser: [{
        id: $("id", "String!"), data:
        {
            name: $("name", "String"),
            member: $("member", "mutationUserUpdate_MemberInput"),
            organiser: $("organiser", "mutationUserUpdate_OrganiserInput"),
        }
    }, {
        id: true,
        email: true,
        name: true,
        roles: true,
        member: {
            avatar: [
                {},
                {
                    url: true,
                },
            ],
            birthday: true,
            gender: true,
            categories: [{
                locale: LocaleInputType.zh
            },
            {
                id: true,
                name: true,
                icon: [{}, {
                    url: true,
                }]
            }],
            phone: true,
            language: true,
            region:true
        },
        organiser: {
            logo: [
                {},
                {
                    url: true,
                },
            ],
            banner: [
                {},
                {
                    url: true,
                },
            ],
            description: true,
            foundedIn: true,
            address: true,
            website: true,
            facebook: true,
            instagram: true,
        }
    }]
});

export default updateUserMutation;