import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";
import { LocaleInputType } from "~/zeus";

const ssoLoginUserMutation = typedGql("mutation")({
    SSOLoginUser: [
    { accessToken: $("accessToken", "String"), type: $("type", "String"), appleJwtClaims: $("appleJwtClaims", "String") },
    {
      token: true,
      exp: true,
      user: {
        id: true,
        email: true,
        roles: true,
        name: true,
        organiser: {
          logo: [
            {},
            {
              url: true,
            },
          ],
          banner: [
            {},
            {
              url: true,
            },
          ],
          description: true,
          foundedIn: true,
          address: true,
          website: true,
          facebook: true,
          instagram: true,
          phone: true,
        },
        member: {
          avatar: [
            {},
            {
              url: true,
            },
          ],
          birthday: true,
          gender: true,
          phone: true,
          categories: [{
            locale: LocaleInputType.zh
          },
          {
            id: true,
            name: true,
            icon: [{}, {
              url: true,
            }]
          }]
        },
      },
    },
  ],
});

export default ssoLoginUserMutation;
